<template lang="pug">

.d-none.d-sm-block
  .row.pb-5.row-flex
      template(v-for="review in reviews")
        .col-md-4.mb-3
          .card.h-100.content
            .card-body.p-5.row-flex
              .card-texts.pb-5 {{ review.review }}
              .bottom.mt-auto
                .card-title.mb-0.fw-bold {{ review.personName }}
                .card-text {{ review.personPosition }}

.d-block.d-sm-none.mt-4
  swiper(:slides-per-view="1" :pagination="true" :autoplay="true" :space-between="15")
    swiper-slide
      .card.h-100.content.mb-5.mx-3
        .card-body.p-5.row-flex
          .card-texts.pb-5 I’ve been searching for the right tool for WhatsApp-based sales for months. My profit has literally increased 5x using Kloser.
          .bottom.mt-auto
            .card-title.mb-0.fw-bold William Leana
            .card-text Sales Consultant, Property Guru
    swiper-slide
      .card.h-100.content.mb-5.mx-3
        .card-body.p-5.row-flex
          .card-texts.pb-5 I’ve been searching for the right tool for WhatsApp-based sales for months. My profit has literally increased 5x using Kloser.
          .bottom.mt-auto
            .card-title.mb-0.fw-bold William Leana
            .card-text Sales Consultant, Property Guru
    swiper-slide
      .card.h-100.content.mb-5.mx-3
        .card-body.p-5.row-flex
          .card-texts.pb-5 I’ve been searching for the right tool for WhatsApp-based sales for months. My profit has literally increased 5x using Kloser.
          .bottom.mt-auto
            .card-title.mb-0.fw-bold William Leana
            .card-text Sales Consultant, Property Guru

</template>

<script>
import { defineComponent } from "vue";
import SwiperCore, { Navigation, Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import "swiper/swiper-bundle.css";

SwiperCore.use([Navigation, Pagination, A11y]);

export default {
  name: "Review",
  components: {
    Swiper,
    SwiperSlide,
    defineComponent,
  },
  data() {
    return {
      reviews: [
        {
          review:
            "I’ve been searching for the right tool for WhatsApp-based sales for months. My profit has literally increased 5x using Kloser.",
          personName: "William Leana",
          personPosition: "Sales Consultant, Property Guru",
        },
        {
          review:
            "Kloser links to Facebook, WhatsApp Lead with such amazing ease. We always faced inaccurate syncing issues with other tools. Now our problem is resolved.",
          personName: "Henry Low",
          personPosition: "Sales Manager, Amway",
        },
        {
          review:
            "We’ve been struggling with Facebook and website lead management for the last two years. Since setting up Kloser, we’ve seen our sales team’s productivity increase by 300%.",
          personName: "Muhammad Idris",
          personPosition: "Sales Consultant, Shakelee",
        },
      ],
    };
  },
};
</script>
