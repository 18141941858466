<template lang="pug">
#intro
  .pt-5
    HeadingLayout(:title="title", :desc="desc")
  .container.pb-5
    Introduction
</template>

<script>
import Introduction from "./Introduction";
import HeadingLayout from "../layout/HeadingLayout";
export default {
  name: "IntroductionList",
  components: { HeadingLayout, Introduction },
  data() {
    return {
      title: "Introducing Kloser",
      desc: "Kloser is a mobile CRM built for sales professionals who run their businesses from their phone. It works with WhatsApp, SMS, email, and other popular chat apps.",
    };
  },
};
</script>
