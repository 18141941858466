<template lang="pug">
.container.py-5.text-center.text-md-start
  .row.align-items-center
    .col-md-6
      .cta-image
        img.img-fluid.mx-auto.d-block(src="/images/growing.png")
    .col-md-6.mt-5.mt-md-0
      .fs-3.fw-bold Start growing with Kloser today
      .fs-6.pb-4.fw-normal With tools to make every part of your sales process more seamless and a support team excited to help you. Get started today with free tools.
      .row
        .col-md-8
          .d-grid.gap-2
            KloserFreeButton
            DownloadAppButton
</template>

<script>
import HeadingLayout from "../layout/HeadingLayout";
import DownloadAppButton from "./DownloadAppButton";
import KloserFreeButton from "./KloserFreeButton";
export default {
  name: "Growing",
  components: { KloserFreeButton, DownloadAppButton, HeadingLayout },
  data() {
    return {
      title: "Start growing with Kloser today",
      desc: "With tools to make every part of your sales process more seamless and a support team excited to help you. Get started today with free tools.",
    };
  },
};
</script>
