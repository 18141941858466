<template lang="pug">
.hero-section.bg-background
  Navbar
  .container
    .row.py-5.align-items-center
      .col-lg-6
        h1.fw-bold.lh-1.mb-3 Build better relationships with your customers.
        .fs-6.fw-normal.pb-4
          | Manage leads & clients, bulk messaging, track response, follow up and set up Google, Facebook and Instagram ads in simpler and cheaper ways.  Everything on your palm, from your phone.
        .row
          .col-md-8
            .d-grid.gap-2
              KloserFreeButton
              DownloadAppButton
      .col-12.col-sm-8.col-lg-6
        .hero-image.pt-5.pt-md-0
          img.d-block.mx-lg-auto.img-fluid(src="/images/hero.png")

</template>

<script>
import Navbar from "./TheNavbar";
import DownloadAppButton from "./DownloadAppButton";
import KloserFreeButton from "./KloserFreeButton";
export default {
  name: "hero",
  components: { KloserFreeButton, DownloadAppButton, Navbar },
};
</script>

<style scoped></style>
