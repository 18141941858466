<template lang="pug">
div(v-for="feature in features")
  .align-items-center.py-3
    .row.align-items-center.g-2.g-sm-5(:class=" feature.reverses === 'left' ? 'flex-md-row-reverse' : 'flex-lg-row' ")
      .col-md-6
        img.d-block.mx-lg-auto.img-fluid(:src="'/images/' + feature.image")
      .col-md-6.text-md-start
        .fs-4.fw-bold.lh-1.mb-3 {{ feature.title }}
        .fs-6.lead.fw-normal {{ feature.description }}
        .read-more
          a.text-decoration-none.text-hyperlink(:href="'/' + feature.more") Read More
        .cursor(v-if="feature.imgType === 'yes'")
          img.cursor.w-50.pt-3(:src="'/images/' + feature.cursor")
</template>

<script>
export default {
  name: "Introduction",
  data() {
    return {
      features: [
        {
          title: "Easily manage your leads",
          description:
            "Send auto-personalised introductions via WhatsApp, SMS, or email with our one-touch Bulk Message feature. No need to type, copy + paste, or even save to your note.",
          image: "easily-manage.png",
          reverses: "right",
          more: "manage-leads",
        },
        {
          title: "The simplest Marketing advertisement tool",
          description:
            "Maximise your business by driving traffic and bringing visitors to your site with ads. Kloser has an easy-to-use Google Ad, Facebook Ad, Instagram Ad creation tool, automate ads set up and performance reporting.",
          image: "advertisement.png",
          reverses: "left",
          more: "advertisement-tool",
        },
        {
          title: "Build Your Unique Online Presence",
          description:
            "Kloser gives you the easier way to create, design, manage and develop your web presence. You will get a FREE domain name which starts with your business name. Whether you’re promoting your business, showcasing your work, opening your store - you can do it all with the Kloser website builder.",
          image: "online-presence.png",
          reverses: "right",
          more: "website-builder",
          imgType: "yes",
          cursor: "cursor.png",
        },
      ],
    };
  },
};
</script>
