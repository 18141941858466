<template lang="pug">
.row.pb-5
  template(v-for="industry in industries")
    .col-md-2.col-6.mx-sm-auto.d-block.pb-3.pb-sm-0
      img.img-fluid.mx-auto.d-block(:src="'/icons/' + industry.icon")
      .fs-5.text-white.text-center {{ industry.industryName }}

</template>

<script>
export default {
  name: "Industry",
  data() {
    return {
      industries: [
        {
          icon: "real-estate.svg",
          industryName: "Real Estate ",
        },
        {
          icon: "automotive.svg",
          industryName: "Automotive",
        },
        {
          icon: "finance.svg",
          industryName: "Finance",
        },
        {
          icon: "electronic.svg",
          industryName: "Electronic",
        },
        {
          icon: "online-business.svg",
          industryName: "E-Commerce",
        },
      ],
    };
  },
};
</script>
