<template lang="pug">
.business.bg-default.py-5.text-white
  HeadingLayout(:title="title", :desc="desc")
  .container
    Industry
    .row.justify-content-center
      .col-12.col-md-4
        KloserFreeButton
</template>

<script>
import Industry from "./Industry";
import HeadingLayout from "../layout/HeadingLayout";
import KloserFreeButton from "./KloserFreeButton";
export default {
  name: "IndustryList",
  components: { KloserFreeButton, HeadingLayout, Industry },
  data() {
    return {
      title:
        "Trusted by more than 10,000 sales professionals in various countries & various industries",
      desc: "Kloser helps sales professionals delight customers and increase sales across dozens of industries around the world.",
    };
  },
};
</script>
