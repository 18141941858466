<template lang="pug">
Hero
IntroductionList
//- IndustryList
//- TestimonialList
Growing
Footer
</template>

<script>
// @ is an alias to /src
import Navbar from "../components/TheNavbar";
import Footer from "../components/TheFooter";
import Hero from "../components/Hero";
import Growing from "../components/Cta";
import IntroductionList from "../components/IntroductionList";
import IndustryList from "../components/IndustryList";
import TestimonialList from "../components/TestimonialList";

export default {
  name: "Home",
  components: {
    TestimonialList,
    IndustryList,
    IntroductionList,
    Growing,
    Hero,
    Footer,
    Navbar,
  },
};
</script>
