<template lang="pug">
template(v-if="url")
  a.btn.btn-primary.mb-2.w-100.fw-bold.py-2(:href="url") Try Kloser for FREE
</template>

<script>
export default {
  name: "KloserFreeButton",
  data() {
    return {
      url: null
    }
  },
  mounted() {
    const searchParams = new URLSearchParams(this.$route.query);
    this.url = process.env.VUE_APP_SIGNUP_BASE_URL + '?' + searchParams.toString();
  }
};
</script>
