<template lang="pug">
.bg-background
  .pt-5
    HeadingLayout(:title="title", :desc="desc")
  .container.pb-5
    Review
    .row.justify-content-center
      .col-12.col-md-4
        KloserFreeButton
</template>

<script>
import Review from "./Testimonial";
import HeadingLayout from "../layout/HeadingLayout";
import KloserFreeButton from "./KloserFreeButton";
export default {
  name: "TestimonialList",
  components: { KloserFreeButton, HeadingLayout, Review },
  data() {
    return {
      title:
        "Learn and grow with the best support and a thriving community behind you.",
      desc: "You don’t have to do it alone. Master the leads methodology and get the most out of your tools with Kloser and a community of thousands of marketing and sales professionals just like you.",
    };
  },
};
</script>
