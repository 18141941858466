<template lang="pug">
.d-flex.d-inline-block.gap-2
  a(href="https://play.google.com/store/apps/details?id=com.kloser.app" target="_blank")
    img.w-100(src="/icons/playstore.png")
  a(href="https://apps.apple.com/us/app/kloser/id1568029738" target="_blank")
    img.w-100(src="/icons/appstore.png")

</template>

<script>
export default {
  name: "DownloadAppButton",
};
</script>

<style>
.butt {
  border: none;
  color: #fff;
  border-radius: 6px;
  padding: 0 1.75rem;
  font-size: 14px;
  background-color: #000000;
  height: 49px;
  line-height: 49px;
  text-transform: capitalize;
}
.hh {
  max-height: 40px;
}
</style>
